import { graphql } from 'gatsby';

import React from 'react';

import Layout from '../shared/components/Layouts/Inner';
import Content from '../shared/components/Content';
import SEO from '../shared/components/SEO';
import ServicesLinks from '../shared/components/Services/ServicesLinks';

import ContainerFixed from '../shared/components/Layouts/ContainerFixed';

import Hero from '../shared/components/Hero/index';
import HeroImage from '../shared/images/banners/newcastle-station.jpg';

import PressReviews from '../shared/components/PressReviews';
import LocationsList from '../shared/components/Locations/List';
import Professionals from '../shared/components/Testimonial/Professionals';
import AppDownload from '../shared/components/AppDownload';
import AboutUs from '../shared/components/About';

export const query = graphql`
  {
    page: contentfulPage(slug: { eq: "home" }) {
      title
      slug
      cleaningProfessionals {
        id
        name
        image {
          file {
            url
            fileName
            contentType
          }
        }
        childContentfulCarouselTextRichTextNode {
          id
          json
        }
        __typename
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout darkMode url="home">
    <SEO title="Home" />
    <Hero image={HeroImage} showReviews heading="Welcome to Maidupnorth">
      <p>
        We provide professional cleaners in Newcastle and Gateshead covering everything from regular
        domestic cleans, office cleans and end of tenancy cleaning.
      </p>
    </Hero>
    <PressReviews />
    <ContainerFixed>
      <Content>
        <AboutUs />
      </Content>
    </ContainerFixed>
    <ServicesLinks />

    <LocationsList />

    <Professionals data={data.page.cleaningProfessionals} />

    <ContainerFixed>
      <AppDownload />
    </ContainerFixed>
  </Layout>
);

export default IndexPage;
