import React from 'react';

import Block from '../Block/index';

import AboutImg from '../../images/jpg/about-us.jpg';

const AboutUs = () => (
  <Block alignCenter>
    <div>
      <h2>About Maidupnorth</h2>
      <p>
        We are a family run cleaning company launched on 18th of January 2016 which prides itself on
        delivering user friendly, caring and professional cleaning services. Our vast array of
        clients include professionals, homeowners, tenants, businesses, landlords, property
        managers, letting agents, students and vulnerable adults.
      </p>
      <p>
        Our band of Mighty Maids work in the heart of our local communities going above and beyond
        to ensure all our clients receive a cleaning service befitting and extending beyond their
        expectations.
      </p>
    </div>
    <img src={AboutImg} alt="About Us" />
  </Block>
);

export default AboutUs;
