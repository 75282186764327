import React from 'react';
import styled from 'styled-components';

import CtaWrapper from '../CtaBlocks/CtaWrapper';
import CtaBlock from '../CtaBlocks/CtaBlock';

const CtaTitle = styled.h2`
  text-align: center;
  padding-top: 2rem;
`;

const ServicesLinks = () => (
  <div>
    <CtaTitle>Our Cleaning Services</CtaTitle>
    <CtaWrapper>
      <CtaBlock title="Regular Cleans" page="/cleaning-services/domestic-house-cleans">
        <p>
          Our highly trained cleaners can be trusted to take care of your time consuming household
          chores freeing up your spare time for relaxation, catching up with family, friends or
          leisure activities. The same cleaner attends whenever possible and all are designated key
          holders.
        </p>
      </CtaBlock>
      <CtaBlock title="Commercial" page="/cleaning-services/commercial-cleaning">
        <p>
          As a highly rated, quick response, VAT registered cleaning company we are the first choice
          for a vast array of landlords, letting agents, property managers and businesses. We
          provide office, communal areas, carpet, deep and end of tenancy commercial cleaning.
        </p>
      </CtaBlock>
      <CtaBlock title="Deep Cleans" page="/cleaning-services/deep-cleaning">
        <p>
          Deep cleaning is an intensive service ideal for removing limescale and residue in
          bathrooms, degreasing kitchens in need of extra attention and spring cleaning throughout.
          Perfect for leaving your home or office sparkly clean for events and get togethers.
        </p>
      </CtaBlock>
      <CtaBlock title="End of Tenancy" page="/cleaning-services/end-of-tenancy-cleaning">
        <p>
          Suitable for move in or move out specialist deep cleaning which follows landlord approved
          checklists. Leave your property with a professional award winning clean for the new
          residents. This inclusive service is not time restricted.
        </p>
      </CtaBlock>
    </CtaWrapper>
  </div>
);

export default ServicesLinks;
